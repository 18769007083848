import { useState, useContext, useEffect} from "react";
import { Row, Col, Card, Container, Accordion  } from 'react-bootstrap';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import PhoneIcon from '../assets/svg/PhoneIcon';
import { ThemeContext, themes } from '../context/ThemeContext';
import MailIcon from "../assets/svg/MailIcon";
import ArrangeCallback from "../components/ArrangeCallback";
import SupportMail from "../components/SupportMail";
import useAuth from "../hooks/useAuth";
import { classNames } from "@syncfusion/ej2-react-buttons";

const Support = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const currentTheme = useContext(ThemeContext);
    const [faqCategories, setFaqCategories] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [available, setAvailable] = useState(false);
    const [company, setCompany] = useState({});
    let TextColor = currentTheme.theme.textColor;

    useEffect(() => {
        const getAvailable = async () => {
            const date = new Date();
            if(date.getUTCDay() > 0 && date.getUTCDay() < 6 && date.getUTCHours() > 7 && date.getUTCHours() < 18){
                setAvailable(true);
            }
            else{
                setAvailable(false);
            }
        }

        getAvailable();

        return () => {
        }
    }, [])

    useEffect(() => {
        const getCategories = async () => {
            try {
                const response = await axiosPrivate.get('/faqs/categories', {              
                });

                setFaqCategories([...response.data]);
            } catch (error) {
                console.error(error);
            }
        }

        getCategories();

        return () => {
        }
    }, [])

    useEffect(() => {
        const getFaqs = async () => {
            try {
                const response = await axiosPrivate.get('/faqs', {              
                });

                setFaqs([...response.data]);
            } catch (error) {
                console.error(error);
            }
        }

        getFaqs();

        return () => {
        }
    }, [])

    useEffect(() => {
        const getCompany = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
                });
          
                setCompany(response.data)
            } catch (error) {
                console.log(error);
            }
        }

        getCompany();

        return () => {
        }
    }, [])

    return (
        <Container className="pt-5 mt-5 generalpage">
            <Row>
                <Col xs={{span : 8, offset: 2}}>
                    <p className="mb-2">Your personal contact</p>

                    <Card>
                        <Row>
                            <Col xs lg={{ span: 6, offset: 3 }} className="py-5">
                                <Row>
                                    <Col xs={4}>
                                        <div className="imagebox">
                                            <img src={`https://api.moldlife.cloud/support_contacts/${company?.support_contact?.image}`} />
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <h4>{company?.support_contact?.name}</h4>
                                        <a class="contactoption tel" href="#"><PhoneIcon color={TextColor} /> {company?.support_contact?.phone}</a>
                                        <a class="contactoption mail" href="#"><MailIcon color={TextColor} /> {company?.support_contact?.email}</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>  

                    <p className="mb-2 mt-4">Contact options</p>
                   
                    <Row role="tablist">
                        <Col xs={4} >
                            <Card className={`contactvia py-4 text-center${available ? '' : ' notavailable'}`}> {/* toggleClass .notavailable */}
                                <div className="availability"></div>
                                <a href="tel:1234567890"> 
                                    <PhoneIcon color={TextColor} />
                                    <button 
                                        className="nav-link active mt-2" 
                                        id="faq-tab" 
                                        data-bs-toggle="tab" 
                                        data-bs-target="#faqPanel" 
                                        type="button" 
                                        role="tab" 
                                        aria-controls="faqpanel" 
                                    >
                                        Phone
                                    </button>
                                </a>
                            </Card>                   
                        </Col>
                        <Col xs={4} >
                            <Card className="contactvia py-4 text-center">
                                <button 
                                    className="nav-link mt-2" 
                                    id="mail-tab" 
                                    data-bs-toggle="tab" 
                                    data-bs-target="#mailPanel" 
                                    type="button" 
                                    role="tab" 
                                    aria-controls="mailPanel" 
                                > 
                                    <MailIcon color={TextColor} />
                                    Mail
                                </button>
                            </Card>                   
                        </Col>
                        <Col xs={4} >
                            <Card className="contactvia py-4 text-center" role="presentation">
                                <button 
                                    className="nav-link mt-2" 
                                    id="callback-tab" 
                                    data-bs-toggle="tab" 
                                    data-bs-target="#callbackPanel" 
                                    type="button" 
                                    role="tab" 
                                    aria-controls="CallbackPanel" 
                                >
                                    <PhoneIcon color={TextColor} callback={true} />
                                    Arrange a callback
                                </button>                              
                            </Card>                   
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} >
                            <div className="tab-content" id="myTabContent">
                                {/* <div className="tab-pane fade" id="faqPanel" role="tabpanel" aria-labelledby="faq-tab"></div> */}
    
                                <div className="tab-pane fade" id="callbackPanel" role="tabpanel" aria-labelledby="callback-tab">
                                    <Card className="p-3 mt-4">
                                    <ArrangeCallback />
                                    </Card>
                                </div>
                                <div className="tab-pane fade" id="mailPanel" role="tabpanel" aria-labelledby="mail-tab"> 
                                    <Card className="p-3 mt-4">
                                        <SupportMail/>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <p className="mb-2 mt-4">FAQ</p>
                
                    <Row role="tablist">

                        {faqCategories?.length
                            ? (faqCategories.map((category, i) => 
                                <Col xs={4} >
                                    <Card>
                                        <button 
                                            className={'nav-link py-2 faq-button' + (i == 0  ? ' active' : '')}
                                            id={`${category.category}-tab`}
                                            data-bs-toggle="tab" 
                                            data-bs-target={`#${category.category}`}
                                            type="button" 
                                            role="tab" 
                                            aria-controls={category.category}
                                            aria-selected={(i == 0  ? true : false) }
                                        >
                                            {category.category}
                                        </button>
                                    </Card>
                                </Col>)) 
                            : ''
                        }
                            
                    </Row>
                    <Row>
                        <Col xs={12} className="mt-2">
                            <Card className="tab-content" > 

                                {faqCategories?.length
                                    ? (faqCategories.map((category, i) => 
                                        <div id={category.category} className={'tab-pane fade' + (i == 0  ? ' active show' : '')} role="tabpanel" aria-labelledby={category.category}>
                                            <Accordion alwaysOpen>

                                            {faqs?.length
                                                ? (faqs.filter(item => item.category == category.category).map((faq, n) => 
                                                    
                                                    <Accordion.Item 
                                                    eventKey={n} 
                                                    className={(n == 0 ? 'show' : '')}
                                                    >
                                                        <Accordion.Header>
                                                            {faq.question}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            {faq.answer}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    )) 
                                                : ''
                                            }
                                        
                                            </Accordion>
                                        </div>)) 
                                    : ''
                                }

                            </Card>  
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Support;
