
const Status = ({block, mold_data}) => {   

    return (
   <>
   <div className="p-3">
    <h3 className="mb-2">{(block.label ? block.label : block.name)}</h3>
    <div className={"status mx-auto " + (mold_data.active ? 'green' : '')}></div>
    </div>
   </>
    
    );
}
export default Status;

