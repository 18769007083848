import { useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import EditMold from './EditMold';
import { Table, Form, Col, Row } from 'react-bootstrap';
import useAuth from "../hooks/useAuth";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

const MoldList = ({ molds, setMolds, moldlist, query, setQuery }) => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    useEffect(() => {
        const getMolds = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
                });
            
                setMolds(response.data.molds);
            } catch (error) {
                console.log(error);
            }
        }

        getMolds();

        return () => {
        }
    }, [])

    const handleActive = async (mold) => {
        try {
            var active = mold.active ? false : true;

            const data = { id: mold.id, active: active };
            
            await axiosPrivate.patch(`/molds`, {
                data
            }).catch(function(error){
                if (error.response) {
                    console.log(error.response.data.message);
                }
            });  
            
            const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
            });
    
            setMolds([...response.data.molds]);
        } catch (error) {
            console.log(error)    
        }
    }
   
    return (
        <>
            <Row>
                <Col sm={8}>
                    <Form.Control 
                        type="text" 
                        placeholder="Search..." 
                        className="mb-2" 
                        value={query}
                        onChange={(e) => setQuery(e.target.value)} 
                    />
                </Col>
            </Row>
            

            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Product</th>
                        <th>Available on Dashboard</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
            {moldlist?.length
                ? (moldlist.map((mold, i) => 
                    <tr key={mold.id}>
                        <td>{mold.code}</td>
                        <td>{mold.product}</td>
                        <td><ButtonComponent 
                                onClick={() => handleActive(mold)} className={mold.active ? "float-end primary" : "float-end"}>
                                    {mold.active ? "available" : "not available"}
                            </ButtonComponent></td>
                        <td><EditMold mold={mold} molds={molds} setMolds={setMolds} /></td>
                    </tr>)) 
                : ''
            }
               
                </tbody>
            </Table>
        </>
    );
};

export default MoldList;
