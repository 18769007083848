import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState , useRef, useEffect    } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, DateTime, DataLabel, LineSeries, Zoom, ScrollBar, MultiColoredLineSeries, StripLine, Crosshair } from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { SwitchComponent,CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import HelpIcon from '../assets/svg/HelpIcon';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import LimitStatic  from './LimitStatic';

              
const TemperatureChart = ({ selectedTool }) => {

    let chart = useRef(); 
    let helpDialogInstance;

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;

    createSpinner({
        target: document.getElementById('panel_9_content'),
    });

    const [message, setMessage] = useState();

   
    const axiosPrivate = useAxiosPrivate(); 

 

    let LSStart = localStorage.getItem('session_Temperaturechart_start');
    let LSEnd = localStorage.getItem('session_Temperaturechart_ende');

    const [labelFormat, setLabelFormat] = useState();   
    const [statedLinedata, setStatedLinedata] = useState([]);
    const date = new Date();
    const lineDaterangeStart = (LSStart ? new Date(LSStart) : new Date());
    const lineDaterangeEnd = (LSEnd ? new Date(LSEnd) : new Date());
    const [lineDaterange, setLineDaterange] = useState([ lineDaterangeStart, lineDaterangeEnd]);// first and last day of current month
    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //TimezoneOffset in milliseconds 

    const handleLineDateChange = (start, ende) => { 
        let temparray = [start, ende]
         setLineDaterange(temparray);
         localStorage.setItem('session_Temperaturechart_start', start);
         localStorage.setItem('session_Temperaturechart_ende', ende);
     };
     
    let LimitStaticObject = (localStorage.getItem("TemperatureLimitStatic") ? JSON.parse(localStorage.getItem("TemperatureLimitStatic")) : null);
    const [staticLimit, setStaticLimit] = useState(LimitStaticObject ? LimitStaticObject.limitStaticActive : false);
    const [notifyStatic , setNotifyStatic ]= useState(LimitStaticObject ? LimitStaticObject.notify : false);
    const [color1, setColor1]= useState(LimitStaticObject ? LimitStaticObject.color1 : Red);
    const [color2, setColor2]= useState(LimitStaticObject ? LimitStaticObject.color2  : Yellow);
    const [limitStatic1, setLimitStatic1]= useState(LimitStaticObject ? LimitStaticObject.limitStatic1 : 30);
    const [limitStatic2, setLimitStatic2] = useState(LimitStaticObject ? LimitStaticObject.limitStatic2 : 24);

    useEffect(() => {
        let unMounted = false;
        showSpinner(document.getElementById('panel_9_content'));
        const getTemperatureChartData = async () => { 
            if(!selectedTool == 0 ){ // selected = mold from map/grid
                let start = new Date (lineDaterange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];
            
                let ende = new Date (lineDaterange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
                
                // const response = await axiosPrivate.get('/molddata/cycles/cycles/mold_id/'+ selectedTool +'/interval/daily/start/' + start + '/end/' + ende, {
                const response = await axiosPrivate.get('/molddata/temperature/1/mold_id/'+ selectedTool +'/start/' + start + ' 00:00/end/' + ende + ' 23:59', {

                }).catch(function(error){
                    if (error.response) {
                        console.error(error.response.data.message);
                    }
                });
    
                !unMounted &&
                    setStatedLinedata([...response.data]);
                    if (response.data.length === 0) {
                        setMessage('No data. change date to continue');
                    }
        
            }
            else{
                setMessage('Select mold from map to continue');
            }
            hideSpinner(document.getElementById('panel_9_content'))
            
        }
        getTemperatureChartData();
        return () => {
            unMounted = true;
        }
    }, [selectedTool, lineDaterange])

    

    let startDate = new Date(lineDaterange[0]);
    let endDate = new Date(lineDaterange[1]);

    var diffDays = endDate.getTime() - startDate.getTime(); 
    diffDays = Number((diffDays / (1000 * 3600 * 24)).toFixed()) + 1;
    const [dateRange, setDateRange] = useState(diffDays);


    const [status, setStatus] = useState(false);

    const buttonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };

    // let t_as = [];
    // let t_ds = [];
    // let t_0 = [];
    // let t_1 = [];


    // kann raus
    // Object.keys(statedLinedata).forEach(function(key) {

    //     let obj_as = {  
    //         time: statedLinedata[key].time, 
    //         temperature: statedLinedata[key].temperature_as, 
    //     }
    //     let obj_ds = {
    //         time: statedLinedata[key].time, 
    //         temperature: statedLinedata[key].temperature_ds, 
    //     }
    //     let obj_0 = {
    //         time: statedLinedata[key].time, 
    //         temperature: statedLinedata[key].temperature_0, 
    //     }
    //     let obj_1 = {
    //         time: statedLinedata[key].time, 
    //         temperature: statedLinedata[key].temperature_1, 
    //     }


    //     t_as.push(obj_as);
    //     t_ds.push(obj_ds);
    //     t_0.push(obj_0);
    //     t_1.push(obj_1);
      
    //   });

    

    let buttons;
    const dlgButtonClick = () => {
        localStorage.setItem("TemperatureLimitStatic", null); 

        if(staticLimit){
            //anpassen
            let th1 = Number(document.getElementById('value_TemperatureStatic1').value);
            let th2 = Number(document.getElementById('value_TemperatureStatic2').value);
            setLimitStatic1(th1)
            setLimitStatic2(th2)
            const staticObject = {
                limitStaticActive : staticLimit,
                limitStatic1 : th1, 
                limitStatic2 : th2, 
                color1 : color1, 
                color2 : color2, 
                notify : notifyStatic,
            }
            localStorage.setItem('TemperatureLimitStatic', JSON.stringify(staticObject)); 
        }
     
        dialogClose();
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: 'Save and close',
                isPrimary: true,
            }
        }
    ];

    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
    //    let text = e.text; dd/mm/yyyy - dd/mm/yyyy
        // setDateRange(e.daySpan);
        handleLineDateChange(start, end);
      }; 

    const marker =  {
        visible: false, 
        // width: 2, 
        // height: 2, 
        // fill: PrimaryColor, 
        // border: { width: 2, color: PrimaryColor },
        // dataLabel: { visible: true, position: 'Top',   font: { fontWeight: '600', color: TextColor }  },
    };

    const primaryxAxis = { 
        valueType: 'DateTime',  
        
        labelFormat: labelFormat, 
        intervalType: 'Auto', 
        desiredIntervals: 12,
        // interval: 2, 
        // labelRotation:  -45 , 
        labelStyle: {color: TextColor},
        enableAutoIntervalOnZooming: true,
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
   };

   const primaryyAxis = {
        majorTickLines: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        title: ' ℃', 
        titleRotation: 0,
        titleStyle: {color: TextColor, size: 16, fontFamily: 'Source Sans Pro' },
        visible: true,
        lineStyle: {width: 0  },
        labelStyle: {color: TextColor},
        // labelFormat: 'n1' ,
        labelFormat: '{value}°C',
        stripLines: [
            { start: limitStatic1, size:  2, sizeType: 'Pixel', color: color1, visible: staticLimit, opacity: 1 }, 
            { start: limitStatic2, size:  2, sizeType: 'Pixel', color: color2, visible: staticLimit, opacity: 1 }
            
        ]
   }

   const loaded = () => {
    hideSpinner(document.getElementById('panel_9_content'));
   }

    const toggleDatespan = (daterange, jumpto = 'future') => {

       
        daterange = (jumpto == "future" ? daterange : -daterange);
        let start = new Date(startDate);
        let end = new Date(endDate);
       
        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

          
         var diffyDays = newend.getTime() - newstart.getTime(); 
         diffyDays = diffyDays / (1000 * 3600 * 24) +1;

         setDateRange(diffyDays);
         handleLineDateChange(newstart, newend);
    };
    const margin = { left: 100, right: 100,  top: 60, bottom: 30 };
    
    const helpButtonClick = () => {
        helpDialogInstance.show();
    }

    const refreshChart = () => { 
        chart.current.refresh();
  
    }
    const tooltip = {
        enable: true, 
    };
    
    const tooltipRender = (args) => {
        let text = args.data.pointX.toLocaleString("de-de") + ' : ' + args.data.pointY + ' ℃';
        args.text = text;
    };


    useEffect(() => {
      if(diffDays < 6 ){
        setLabelFormat('d.M.y <br> H:mm')    
      }
      else{
        setLabelFormat('d.M')
      }
      }, [lineDaterange]);
    


return (
<>   
<div className="widgetheader">
        <div className="float-end" onClick={buttonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header='Temperature Output' 
            showCloseIcon={true} 
            visible={false}
            width='250px' height='150px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_9_content'> 
             Temperature </DialogComponent>

        <DialogComponent 
            id="defaultdialogLine" 
            showCloseIcon={false} 
            zIndex = {9999}
            width="500px" 
            header="Settings " 
            visible={status}
            target="#panel_9_content"
            buttons={buttons}
            open={dialogOpen}
            close={dialogClose}
            position={{ X: 'center', Y: '40' }}
            >
          <Row>                
          <Col sm={5} className='py-3'>  
                        <label htmlFor="switch1"> Static Limits </label>
                    </Col>
                    <Col sm={7} className='py-3'>  
                        <SwitchComponent id="switch1" name='static' checked={staticLimit} change={() => setStaticLimit(!staticLimit)} />
                    </Col>
                    {(staticLimit == false ?  null : 
                        <>  
                            <LimitStatic name="TemperatureStatic1" color={color1} setColor={setColor1} value={limitStatic1} setValue={setLimitStatic1} placeholder="Upper Limit" useActivate={false} min={limitStatic2}  />

                            <LimitStatic name="TemperatureStatic2" color={color2} setColor={setColor2} value={limitStatic2} setValue={setLimitStatic2} placeholder="Lower Limit" useActivate={false} max={limitStatic1} />
                        
                            <Col sm={6} className='py-3 mb-3'>  
                                <label htmlFor="switch1"> Notification by exceeding Limit </label>
                            </Col>
                            <Col sm={6} className='py-3 mb-3'>  
                                <CheckBoxComponent id="notificationstatic" name='notificationstatic' checked={notifyStatic} change={() => setNotifyStatic(!notifyStatic)} />
                            </Col>
                        </>
                    )}
        </Row>
        </DialogComponent>
        <div className="datecontainer">
        <DateRangePickerComponent 
            id="daterangepicker" 
            startDate={startDate} 
            endDate={endDate} 
            placeholder="Select a range" 
            change={onChange}
            format='dd.MM.yyyy'
            firstDayOfWeek = {1}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} fontSize={28} val={" -" + dateRange + "d"}  /> </div>
    <div className="timelapse future" onClick={() => toggleDatespan(dateRange )}> <TimelapseFuture color={TextColor} fontSize={28} val={" +" + dateRange + "d"}  /> </div>

    {(statedLinedata.length == 0 ? <h2 className='text-center pt-5'>{message}</h2> : 
    <>
    <ChartComponent
    loaded = {loaded}
        style={{ backgroundColor : BackgroundColor, color: TextColor}} 
        // height='100%'
        chartArea={{ border: { width: 0 } }} 
        enableCanvas= {false}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        zoomSettings={{
            enableSelectionZooming: true,
            enableScrollbar: true,
            enablePan: true,
            toolbarItems: ['Reset'],
            mode: "X",
        }}
        margin={margin} 
        title='Temperature profile'
        titleStyle={{color: TextColor, fontWeight: '400', position: 'top'}}
        ref={chart} 
        tooltip={tooltip}
        tooltipRender={tooltipRender}
        enableAnimation = {false}
        crosshair={{ enable: true, lineType: 'Vertical' }}
        >
            <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category,  DateTime, Zoom, ScrollBar, MultiColoredLineSeries, StripLine, Crosshair]}/>
            <SeriesCollectionDirective>
                <SeriesDirective 
                    // dataSource={t_as} 
                    dataSource={statedLinedata} 
                    xName='time' 
                    yName='temperature_as' 
                    fill={PrimaryColor} 
                    width={1} 
                    name='T (AS)' 
                    type='Line' 
                    enableAnimation = {false}
                
                    marker={marker}
                  segmentAxis="Y"
                > 
                
                    {/* <SegmentsDirective>
                        <SegmentDirective value={color1threshold} color={Red}></SegmentDirective>
                        <SegmentDirective value={color2threshold} color={Yellow}></SegmentDirective>
                        <SegmentDirective color={PrimaryColor}></SegmentDirective>
                    </SegmentsDirective> */}
                </SeriesDirective>
                
                 <SeriesDirective 
                    // dataSource={t_ds} 
                    dataSource={statedLinedata} 
                    xName='time' 
                    yName='temperature_ds' 
                    fill={Yellow} 
                    width={1} 
                    name='T (DS) ' 
                    // type='Line' 
                    type="Line"
                    marker={marker}
                    segmentAxis="Y"
                    legendShape='Rectangle'
                    
enableAnimation = {false}


                    /> 
                <SeriesDirective 
                    // dataSource={t_0} 
                    dataSource={statedLinedata} 
                    xName='time' 
                    yName='temperature_0' 
                    fill={Red} 
                    width={1} 
                    name='T (0)' 
                    // type='Line' 
                    type="Line"
                    marker={marker}
                    segmentAxis="Y"
                    legendShape='Rectangle'
                    enableAnimation = {false}
                    /> 
                 <SeriesDirective 
                    // dataSource={t_1} 
                    dataSource={statedLinedata} 
                    xName='time' 
                    yName='temperature_1' 
                    fill={Blue} 
                    width={1} 
                    name='T (1)' 
                    type='Line' 
                    marker={marker}
                    segmentAxis="Y"
                    legendShape='Rectangle'
                    enableAnimation = {false}
                    /> 
            </SeriesCollectionDirective>
    </ChartComponent> 
    </>
   ) 
    }
   


</>
)

}
;
export default TemperatureChart;
