import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useEffect, useRef } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, DateTime, DataLabel, ScatterSeries,LineSeries, Zoom, StripLine  } from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { SwitchComponent,CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import LimitStatic  from './LimitStatic';
import LimitDynamic from './LimitDynamic';
// Datepicker format:
// https://www.syncfusion.com/forums/143984/change-date-picker-view-format-to-week-or-month

   
const NoiseIDNAverage = ({ selectedTool }) => {

    let chart = useRef(); 
    const axiosPrivate = useAxiosPrivate();   

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;
    let helpDialogInstance;

    
    let LimitStaticObject = (localStorage.getItem("NoiseIDNAverageLimitStatic") ? JSON.parse(localStorage.getItem("NoiseIDNAverageLimitStatic")) : null);
    const [staticLimit, setStaticLimit] = useState(LimitStaticObject ? LimitStaticObject.limitStaticActive : false);
    const [notifyStatic , setNotifyStatic ]= useState(LimitStaticObject ? LimitStaticObject.notify : false);
    const [color1, setColor1]= useState(LimitStaticObject ? LimitStaticObject.color1 : Red);
    const [color2, setColor2]= useState(LimitStaticObject ? LimitStaticObject.color2  : Yellow);
    const [limitStatic1, setLimitStatic1]= useState(LimitStaticObject ? LimitStaticObject.limitStatic1 : 700);
    const [limitStatic2, setLimitStatic2] = useState(LimitStaticObject ? LimitStaticObject.limitStatic2 : 400);

    let LimitDynamicObject= (localStorage.getItem("NoiseIDNAverageLimitDynamic") ? JSON.parse(localStorage.getItem("NoiseIDNAverageLimitDynamic")) : null);
    const [dynamicLimit, setDynamicLimit] = useState(LimitDynamicObject ? LimitDynamicObject : false);
    const [notifyDynamic, setNotifyDynamic] = useState(LimitDynamicObject ? LimitDynamicObject.notify : false);
    const [average, setAverage] = useState(LimitDynamicObject ? LimitDynamicObject.average : 50);
    const [color3, setColor3]= useState(LimitDynamicObject ? LimitDynamicObject.color1 : PrimaryColor);
    const [color4, setColor4]= useState(LimitDynamicObject ? LimitDynamicObject.color2  : Blue);
    const [lowerToleranceActive, setLowerToleranceActive] = useState(LimitDynamicObject ? LimitDynamicObject.lowerToleranceActive : false);
    const [tolerance1, setTolerance1] = useState(LimitDynamicObject ? LimitDynamicObject.tolerance1 : 0.1);
    const [tolerance2, setTolerance2] = useState(LimitDynamicObject ? LimitDynamicObject.tolerance2 : 0.1);


    createSpinner({
        target: document.getElementById('panel_7_content'),
    });
    const [message, setMessage] = useState('Select mold from map to continue');

    const date = new Date();
    const [statedNoiseData, setStatedNoiseData] = useState([]);
    
   

    let firstDayofCurrentWeek = date.getDate() - date.getDay() + 1;
    firstDayofCurrentWeek =  new Date(date.setDate(firstDayofCurrentWeek)).toUTCString();
    
    let LSStart = localStorage.getItem('session_scatter_start');
    let LSEnd = localStorage.getItem('session_scatter_end');

    const calculateDaysBetweenDates = (date1, date2)  =>{
        const startDate = new Date(date1);
        const endDate = new Date(date2);
    
        const timeDifference = endDate - startDate;
    
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const daysDifference = timeDifference / millisecondsPerDay +1;
    
        return Math.round(daysDifference);
    }

    const [dateRange, setDaterange] = useState(LSStart ? calculateDaysBetweenDates(LSStart, LSEnd) : 7);

    let lastDayofCurrentWeek = new Date(date.setDate(date.getDate()+6)).toUTCString();
    

    const [scatterDateRange, setScatterDateRange] = useState([new Date(LSStart ? LSStart : firstDayofCurrentWeek), new Date(LSEnd ? LSEnd : lastDayofCurrentWeek)]);// first and last day of current week

    let start = new Date(scatterDateRange[0]);
    let end = new Date(scatterDateRange[1]);

    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
   
    useEffect(() => {

        showSpinner(document.getElementById('panel_7_content'));
        let unMounted = false;
        const getNoiseData = async () => {
            if(!selectedTool == 0  ){ // selected = mold from map/grid

        
                let start = new Date (scatterDateRange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];

                let ende = new Date (scatterDateRange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
                
                
                const response = await axiosPrivate.get('/molddata/noise/1/mold_id/'+ selectedTool +'/start/' + start + ' 00:00/end/' + ende + ' 23:00', {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
                !unMounted &&
                setStatedNoiseData([...response.data]);     
                if(response.data.length === 0){
                    setMessage('Change daterange to view Noise IDN');
                }
        
            }
            else{
                setMessage('Select mold from map and/or change daterange to view Noise IDN');   
            }
            hideSpinner(document.getElementById('panel_7_content'))   
           
        }

        getNoiseData();

        return () => {
            unMounted = true;
        }
    }, [selectedTool, scatterDateRange]);  

  
    const handleScatterDateChange = (start, ende) => { 
       let temparray = [start, ende] 
       setScatterDateRange(temparray);
       localStorage.setItem('session_scatter_start', start);
       localStorage.setItem('session_scatter_end', ende);
    };

    const marker = { visible: false, width: 2, height: 2, shape: 'Circle'};
    const [status, setStatus] = useState(false);

    const settingsButtonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        refreshChart();
        setStatus(false);
        
  
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };

    let buttons;
    const dlgButtonClick = () => {
        
        localStorage.setItem("NoiseIDNAverageLimitStatic", null); 

        if(staticLimit){
            const staticObject = {
                limitStaticActive : staticLimit,
                limitStatic1 : limitStatic1, 
                limitStatic2 : limitStatic2, 
                color1 : color1, 
                color2 : color2, 
                notify : notifyStatic,
            }
            localStorage.setItem('NoiseIDNAverageLimitStatic', JSON.stringify(staticObject)); 
        }
        localStorage.setItem("NoiseIDNAverageLimitDynamic", null); 
        if(dynamicLimit){
            let avg = Number(document.getElementById('dynamicAverage_dynamic1').value);
          
            const dynamicObject = {
                limitStaticActive : dynamicLimit,
                average : avg, 
                lowerToleranceActive : lowerToleranceActive,
                tolerance1 : tolerance1, 
                tolerance2 : lowerToleranceActive ? tolerance2 : tolerance1, 
                color1 : color3, 
                color2 : color4, 
                notify : notifyDynamic,
            }
            localStorage.setItem('NoiseIDNAverageLimitDynamic', JSON.stringify(dynamicObject));
        }
        refreshChart();
        dialogClose();
        
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: 'Save and Close',
                isPrimary: true,
            }
        }
    ];


    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
    //    let text = e.text; dd/mm/yyyy - dd/mm/yyyy
        setDaterange(e.daySpan);
        handleScatterDateChange(start, end);
        refreshChart();
      }; 
      const toggleDatespan = (daterange, jumpto = 'future') => {

        daterange = (jumpto == "future" ? daterange : -daterange);
        start = new Date(start);
        end = new Date(end);

        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

        handleScatterDateChange(newstart, newend);
    };

    const refreshChart = () => { 
        if(chart.current){
            chart.current.refresh();
            console.log('refreshed')
        }
            
        
    }
    useEffect(() => {
        if(chart.current){
            refreshChart();
        }
        
      }, [dynamicLimit]);

   // const data = statedNoiseData;
    const primaryxAxis = { 
        valueType: 'DateTime',  
        // title: 'Time', 
        labelFormat: 'dd.MM - h:mm',
        //  interval: 1, 
        labelRotation:  -45 , 
        labelStyle: {color: TextColor}, 
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        enableAutoIntervalOnZooming: true,
    };
    const primaryyAxis = {
        // title: 'Value',
        majorTickLines: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        lineStyle: {width: 0  },
        labelFormat: 'n1' ,
        labelStyle: {color: TextColor}, 
        stripLines: [
            { start: limitStatic1, size:  2, sizeType: 'Pixel', color: color1, visible: staticLimit, opacity: 1 }, 
            { start: limitStatic2, size:  2, sizeType: 'Pixel', color: color2, visible: staticLimit, opacity: 1 }
            
        ]
        // interval: 10,
    };
  
    const margin = { left: 100, right: 100, top: 60, bottom: 40 };
    const helpButtonClick = () => {
        helpDialogInstance.show();
    }
    const loaded = () => {
        hideSpinner(document.getElementById('panel_7_content'));
    }

    const calculateMovingAverage = (datasrc, windowSize) => {
        const result = [];
        for (let i = 0; i <= datasrc.length - windowSize; i++) {
          const window = datasrc.slice(i, i + windowSize);
          const averageCalc = window.reduce((sum, entry) => sum + entry.noise_idn_avg, 0) / windowSize;
          let upperBound = averageCalc * (1 + tolerance1 );
          let lowerBound = averageCalc * (1 - (lowerToleranceActive ? tolerance2: tolerance1) );
          const timestamp = window[window.length - 1].time;
          result.push({ averageCalc, upperBound, lowerBound, timestamp });
        }
        return result;
       
      };

      const [movingAverageData, setMovingAverageData] = useState([]);

     
      useEffect(() => {
        
        let maData = 0;
       if(dynamicLimit){
        maData = calculateMovingAverage(statedNoiseData, average);
       }
         
        setMovingAverageData(maData);
      }, [average, tolerance1, tolerance2, statedNoiseData, scatterDateRange, dynamicLimit]);
        
return (
<>   

    <div className="widgetheader">
        <div className="float-end" onClick={settingsButtonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header='Noise IDN Average' 
            showCloseIcon={true} 
            visible={false}
            width='250px' height='150px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_7_content'
            
            >  
            Display of the average Noise IDN points of all sensors over time - Standard period 24h - One value per cycle  
        </DialogComponent>

        <DialogComponent 
            id="defaultdialogScatter" 
            showCloseIcon={false} 
            zIndex = {9999}
            width="500px" 
            header="Settings" 
            visible={status}
            target="#panel_7_content"
            buttons={buttons}
            open={dialogOpen}
            close={dialogClose}
            position={{ X: 'center', Y: '40' }}
        >
            <Row>                
                    <Col sm={5} className='py-3'>  
                        <label htmlFor="switch1"> Static Limits </label>
                    </Col>
                    <Col sm={7} className='py-3'>  
                        <SwitchComponent id="switch1" name='static' checked={staticLimit} change={() => setStaticLimit(!staticLimit)} />
                    </Col>
                    {(staticLimit == false ?  null : 
                        <>  
                            <LimitStatic name="static1" color={color1} setColor={setColor1} value={limitStatic1} setValue={setLimitStatic1} placeholder="Upper Limit" useActivate={false} min={limitStatic2}  />

                            <LimitStatic name="static2" color={color2} setColor={setColor2} value={limitStatic2} setValue={setLimitStatic2} placeholder="Lower Limit" useActivate={false} max={limitStatic1} />
                        
                            <Col sm={6} className='py-3 mb-3'>  
                                <label htmlFor="checkbox1"> Notification by exceeding Limit </label>
                            </Col>
                            <Col sm={6} className='py-3 mb-3'>  
                                <CheckBoxComponent id="checkbox1" name='notificationstatic' checked={notifyStatic} change={() => setNotifyStatic(!notifyStatic)} />
                            </Col>
                        </>
                    )}
                    <Col sm={5}>  
                        <label htmlFor="switch2" style={{ padding: "10px 85px 10px 0" }}> Dynamic Limits </label>
                    </Col>
                    <Col sm={7}>  
                        <SwitchComponent id="switch2" name='dynamic' checked={dynamicLimit} change={() => setDynamicLimit(!dynamicLimit)} />
                    </Col>
                    {(dynamicLimit == false ? null : 
                        <> 

                            <LimitDynamic name="dynamic1" color1={color3} setColor1={setColor3} color2={color4} setColor2={setColor4} average={average} setAverage={setAverage} tolerance1={tolerance1} setTolerance1={setTolerance1} tolerance2={tolerance2} setTolerance2={setTolerance2} lowerToleranceActive={lowerToleranceActive} setLowerToleranceActive={setLowerToleranceActive} />

                   <div className="clear"></div>
                   <Col sm={6} className='py-3'>  
                        <label htmlFor="checkbox2"> Notification by exceeding Threshold </label>
                    </Col>
                    <Col sm={6} className='py-3'>  
                        <CheckBoxComponent id="checkbox2" name='notification' checked={notifyDynamic} change={() => (setNotifyDynamic(!notifyDynamic))} />
                    </Col>

                   </>
                    )}
            </Row>
            <div className="p5"></div>
        </DialogComponent>
        <div className="datecontainer">
            <DateRangePickerComponent 
                id="daterangepicker" 
                startDate={start} 
                endDate={end} 
                placeholder="Select a range" 
                change={onChange}
                format='dd.MM.yyyy'
                firstDayOfWeek = {1}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} val={" - " + dateRange + "d"}  /> </div>
    <div className="timelapse future" onClick={() => toggleDatespan(dateRange )}> <TimelapseFuture color={TextColor} val={" + " + dateRange + "d"}  /> </div>


{/* {(data.length == 0 ? <h2 className='text-center pt-5'> {selectedTool ? 'Change ' : 'Select mold from map and/or change'}  daterange to view Noise IDN</h2> :  */}

{(statedNoiseData.length == 0 ? <h2 className='text-center pt-5'> { message} </h2> : 
     
    <ChartComponent 
        ref={chart} 
        style={{ backgroundColor : BackgroundColor, color: TextColor}}
        background={BackgroundColor}
        primaryXAxis={primaryxAxis} 
        primaryYAxis={primaryyAxis} 
        margin={margin}         
        enableCanvas={true} // false: better qualitiy, lower performance
        enablePersistence={false}
        loaded = {loaded}
        legendSettings = {{visible: false}}
        zoomSettings={{
            enableSelectionZooming: true,
            enableScrollbar: true,
            enablePan: true,
            toolbarItems: ['Reset'],
            mode: "X, Y",
        }}
        title='Noise IDN Average'
        titleStyle={{color: TextColor, fontWeight: '400', position: 'Top', fontSize : '10'}}
        chartArea={{ border: { width: 0 } }} 

        >
        <Inject services={[ScatterSeries, LineSeries, Legend, DataLabel, Category, Zoom, DateTime, StripLine]}/>
        <SeriesCollectionDirective>
                  
            <SeriesDirective dataSource={movingAverageData} xName='timestamp' yName='upperBound' name="Upper Bound" type='Line' fill={color3} opacity='1' visible={dynamicLimit}></SeriesDirective> 
            <SeriesDirective dataSource={movingAverageData} xName='timestamp' yName='lowerBound' name="Lower Bound" type='Line' fill={color4} opacity='1' visible={dynamicLimit}></SeriesDirective> 
          
            <SeriesDirective 
                dataSource={statedNoiseData} 
                xName='time' 
                yName='noise_idn_avg'
                // name='noise_idn_avg' 
                type='Scatter' 
                fill={PrimaryColor}
                opacity={0.5}
                marker={marker}
                close='noise_idn_avg'
            >
            </SeriesDirective>    
      </SeriesCollectionDirective>
    </ChartComponent>
    
)}
</>
)

}
;
export default NoiseIDNAverage;
