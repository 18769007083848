import useAxiosPrivate from "../hooks/useAxiosPrivate";
import React, { useState, useEffect } from "react";
import { Modal, Alert } from 'react-bootstrap';
import useAuth from "../hooks/useAuth";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

const EditMold = ({ mold, molds, setMolds }) => { 
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [product, setProduct] = useState(mold.product);
    const [show, setShow] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setErrMsg('');
    }, [product]);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            let errorMessage = '';           
           
            const data = { id: mold.id, product };
            
            await axiosPrivate.put(`/molds`, {
                data
            }).catch(function(error){
                if (error.response) {
                    errorMessage = error.response.data.message;
                }
            }); 
            
            
            const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
            });
    
            setMolds(response.data.molds);

            errorMessage ? setErrMsg(errorMessage) : handleClose();   
           
        } catch (error) {
            console.log(error)    
        }
    }

    return (
        <>
            <ButtonComponent onClick={handleShow} className="float-end primary">
                Edit
            </ButtonComponent>

            <Modal show={show} onHide={handleClose}>
                <form onSubmit={e => handleSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title><h3>Edit mold {mold.code}</h3></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Alert show={errMsg !== ''} variant="danger">{errMsg}</Alert>
                        
                        <div className="mb-2">
                            <label htmlFor="name">Product</label> 
                            <input
                                type="text"
                                id="product"
                                autoComplete="off"
                                required
                                className="form-control"
                                value={product}
                                onChange={e => setProduct(e.target.value)}
                            />
                        </div>

                    </Modal.Body>
                    
                    <Modal.Footer>
                        <ButtonComponent className="primary" type="submit" onClick={e => handleSubmit(e)}>
                            Save
                        </ButtonComponent>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default EditMold;