import { useState, useEffect } from "react";
import UserList from '../components/UserList';
import useLocalStorage from "../hooks/useLocalStorage";
import { Card } from 'react-bootstrap';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [userlist, setUserlist] = useState({});
    const [query, setQuery] = useLocalStorage('search_user', '');

    useEffect(() => {   
        function search(){
            const results = users.filter(users => {
                if (query === "") return users
                return users.name.toLowerCase().includes(query.toLowerCase()) || users.email.toLowerCase().includes(query.toLowerCase())
            })

            setUserlist(results);
        }

        search();       
    }, [users, query]);

    return (
        <> 
        <Card.Title>User </Card.Title> 
        <Card.Body>
            <UserList users={users} setUsers={setUsers} userlist={userlist} query={query} setQuery={setQuery} />
        </Card.Body>   
          
           
                        
        </>
    )
}

export default Users
