import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwt_decode from "jwt-decode";

const RequireAuth = ({ allowedMode }) => {
    const { auth } = useAuth();
    const location = useLocation();

    if(!auth.accessToken){
        
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    
    const userInfo = jwt_decode(auth.accessToken).userInfo;
   
    return (
        (userInfo.modes?.includes(allowedMode) || allowedMode === 0)
            ? <Outlet />
            : auth?.accessToken 
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;