import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Card, Container, Col, Row, Alert } from 'react-bootstrap';
import { criticals, errors, warnings } from '../config/alerts';
import useAuth from '../hooks/useAuth';
import AlertEmail from '../emails/Alert';
import { render } from "@react-email/render";
import ToggleDark from '../components/toggleDark';
import { ThemeContext } from '../context/ThemeContext';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";


const Alerts = () => {
    const axiosPrivate = useAxiosPrivate();
    const [alerts, setAlerts] = useState([]);
    const [allMolds, setAllMolds] = useState({});
    const [selectedMold, setSelectedMold] = useState('');
    const [selectedAlert, setSelectedAlert] = useState('');
    const [email, setEmail] = useState('');
    const [parallelismX, setParallelismX] = useState(0.00);
    const [parallelismY, setParallelismY] = useState(0.00);
    const [temperatureMin, setTemperatureMin] = useState(0);
    const [temperatureMax, setTemperatureMax] = useState(0);
    const [subject, setSubject] = useState('[Alert] in Mold: [Mold]');
    const [notice, setNotice] = useState('');
    const [noticeType, setNoticeType] = useState('');
    const [updated, setUpdated] = useState(0);
    const { auth } = useAuth();
    // const [darkMode, setDarkMode] = useState(true);
    const { theme, toggle, dark } = useContext(ThemeContext);


    useEffect(() => {
        const getAlerts = async () => {
            try {
                const response = await axiosPrivate.get('/alerts/' + auth.userInfo.id, {              
                });
                
                setEmail(response.data.email);   
                setSubject(response.data.subject);   
                setAlerts(response.data.alerts)
                setParallelismX(response.data.parallelism_x)
                setParallelismY(response.data.parallelism_y)
                setTemperatureMin(response.data.temperature_min)
                setTemperatureMax(response.data.temperature_max)
            } catch (error) {
                console.log(error);
            }
        }

        getAlerts();

        return () => {
        }
    }, [updated])

    useEffect(() => {
        const getAllMolds = async () => {
            try {
                const response = await axiosPrivate.get('/companies/' + auth.userInfo.companies_id, {
                });
        
                setAllMolds(response.data.molds);
            } catch (error) {
                console.log(error);
            }
        }

        getAllMolds();

        return () => {
        }
    }, [])

    const changeAlert = (alert_key) => {

        if(alerts.includes(alert_key)){
            setAlerts(alerts.filter(e => e !== alert_key ))   
        } 
        else{
            setAlerts([...alerts, alert_key]);
        }    

    }

    const isAlert = (alert_key) => {
        return alerts.includes(alert_key); 
    }

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            let errorMessage = '';
          
            const data = { users_id: auth.userInfo.id, alerts, email, subject, parallelism_x: parallelismX, parallelism_y: parallelismY, temperature_min: temperatureMin, temperature_max: temperatureMax };
        
            await axiosPrivate.put(`/alerts`, {
                data
            }).catch(function(error){
                if (error.response) {
                    errorMessage = error.response.data.message;
                }
            }); 
            
            if(errorMessage){
                setNotice(errorMessage);
                setNoticeType('danger'); 
            }
            else{
                setNotice('Changes saved');
                setNoticeType('success'); 
            }

            setUpdated(updated + 1)
        } catch (error) {
            console.log(error)    
        }
    }

    const sendMail = async e => {
        try {
            const data = { 
                users_id: auth.userInfo.id,
                to: email, 
                subject: subject.replace('[Mold]', selectedMold).replace('[Alert]', selectedAlert), 
                html: render(<AlertEmail alert={selectedAlert} mold={selectedMold} />)
            };

            await axiosPrivate.post(`/email`, {
                data
            }).catch(function(error){
                if (error.response) {
                    console.log(error.response.data.message)  
                }
            }); 
        } catch (error) {
            console.log(error)    
        }
    }   

    return (
        <>     
        <Card.Title>Alerts </Card.Title> 
        <Card.Body>
            <Row>
                <Col sm={12}>
                    <h4>Display Settings</h4>
                    <ToggleDark
                        toggle={toggle}
                        dark={dark}>

                    </ToggleDark>            
                </Col>
            </Row>

        
            <form onSubmit={e => handleSubmit(e)}>
                
                <Alert show={notice !== ''} variant={noticeType}>{notice}</Alert>

                <div className="mb-4">
                    <label htmlFor="email">Email Address of Client</label>
                    <input
                        type="text"
                        id="email"
                        autoComplete="off"
                        required
                        placeholder="user1@example.com, user2@example.com"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>  

                <div className="mb-4">
                    <label htmlFor="subject">Subject</label>
                    <input
                        type="text"
                        id="subject"
                        autoComplete="off"
                        required
                        
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                    />
                </div> 

                [Alert] - Alert
                <br />
                [Mold] - Mold ID
                <br />
                <br />

                <h3>Alert Library</h3>

                <h4>Critical</h4> 

                <Row>    
                { Object.keys(criticals).map(alert_key => {
                    return (
                    <Row>
                        <Col sm={12} md={6}>
                            <label className="cbox">
                                {criticals[alert_key]}
                                <input 
                                id={`alert_${alert_key}`}
                                onChange={() => changeAlert(`${alert_key}`)}
                                // checked={isAlert(`${alert_key}`)}
                                checked={(isAlert(alert_key) ? 'checked' : '')}
                                type="checkbox"
                                />
                                <span className="checkmark"></span>
                            </label>
                        </Col>
                            
                           
                            {alert_key === 'parallelism critical' ?
                            <Col sm={10}  className={isAlert(alert_key) ? '' : 'd-none'}>
                                <Row>
                                    <Col sm={4} className="pt-3">
                                        <label> Set critical range boundaries </label>
                                    </Col>
                                    <Col sm={3}>
                                        <label> x (mm)</label>
                                    <input
                                        type="text"
                                        id="parallelism_x"
                                        value={parallelismX}
                                        onChange={e => setParallelismX(e.target.value)}
                                    />
                                    </Col>
                                    <Col sm={3}>
                                        <label> y (mm)
                                        </label><input
                                        type="text"
                                        id="parallelism_y"
                                        value={parallelismY}
                                        onChange={e => setParallelismY(e.target.value)}
                                    />
                                    </Col>
                                </Row> 
                            </Col>:
                            alert_key === 'temperature critical' ?
                            <Col sm={10}className={isAlert(alert_key) ? '' : 'd-none'}>
                                <Row>
                                    <Col sm={4} className="pt-3">
                                    <label>  Enter critical range boundaries </label>
                                    </Col>
                                    <Col sm={3}>
                                        <label>min (°C)</label>
                                            
                                        <input
                                        type="text"
                        
                                        id="temperature_min"
                                        value={temperatureMin}
                                        onChange={e => setTemperatureMin(e.target.value)}
                                    />
                                    </Col>
                                    <Col sm={3}>
                                        <label>max (°C)</label>
                                        <input
                                        type="text"
            
                                        id="temperature_max"
                                        value={temperatureMax}
                                        onChange={e => setTemperatureMax(e.target.value)}
                                    />
                                    </Col>
                                </Row> 
                            </Col>:
                                <div className="clear"></div>}
                        
                    </Row>
                    )
                })}

                </Row>

           
                <h4 className="mt-3">Error</h4> 
                <Row>
                { Object.keys(errors).map(alert_key => {
                    return (
                 
               
                    <Col sm={12}>
                        <label className="cbox">
                            {errors[alert_key]}
                            <input 
                                id={`alert_${alert_key}`}
                                onChange={() => changeAlert(`${alert_key}`)}
                                // checked={isAlert(`${alert_key}`)}
                                checked={(isAlert(alert_key) ? 'checked' : '')}
                                type="checkbox"
                                />
                            <span className="checkmark"></span>
                        </label>
                    </Col>
                      
                    )
                })}

                </Row>

              

                <h4 className="mt-3">Warning</h4> 
                <Row>
                { Object.keys(warnings).map(alert_key => {
                    return (
             
                        <Col sm={12}>
                        <label className="cbox">
                            {warnings[alert_key]}
                            <input 
                                id={`alert_${alert_key}`}
                                onChange={() => changeAlert(`${alert_key}`)}
                                // checked={isAlert(`${alert_key}`)}
                                checked={(isAlert(alert_key) ? 'checked' : '')}
                                type="checkbox"
                                />
                            <span className="checkmark"></span>
                        </label>
                    </Col>
                        )
                })}
                   
                        <Col sm={12} className="my-3">         
                            <ButtonComponent className="primary" onClick={e => handleSubmit(e)}>
                                Save
                            </ButtonComponent>
                        </Col> 
                    </Row>

            </form>

            <Row>
               
                <Col sm={12} className="mt-5">
                    <h5>Test Email</h5>
                </Col>
                <Col sm={3}>
                    <select 
                        
                        value={selectedMold}
                        onChange={(e) => setSelectedMold(e.target.value)}>
                        <option disabled hidden value={''}>Mold...</option>
                        {allMolds?.length
                            ? (allMolds.map((mold, i) => 
                                <option value={mold.code}>
                                    {mold.code}
                                </option>)) 
                            : ''
                        }
                    </select>
                </Col>
                <Col sm={7}>
                    <select 
                        
                        value={selectedAlert}
                        onChange={(e) => setSelectedAlert(e.target.value)}>
                        <option disabled hidden value={''}>Alert...</option>
                        <optgroup label="Critical">

                        { Object.keys(criticals).map(alert_key => {
                            return (
                                <option value={criticals[alert_key]}>
                                    {criticals[alert_key]}
                                </option>
                            )
                        })}

                        </optgroup>
                        <optgroup label="Error">

                        { Object.keys(errors).map(alert_key => {
                            return (
                                <option value={errors[alert_key]}>
                                    {errors[alert_key]}
                                </option>
                            )
                        })}

                        </optgroup>
                        <optgroup label="Warning">

                        { Object.keys(warnings).map(alert_key => {
                            return (
                                <option value={warnings[alert_key]}>
                                    {warnings[alert_key]}
                                </option>
                            )
                        })}

                        </optgroup>
                    </select>
                </Col>
                <Col sm={2}>
                    <ButtonComponent  className="primary" onClick={e => sendMail(e)}>
                        Send
                    </ButtonComponent>
                </Col>
            </Row>
        </Card.Body> 
                      
        </>
    )
}

export default Alerts
