import { ColumnDirective, ColumnsDirective, GridComponent,Inject, Page, Toolbar,Sort } from '@syncfusion/ej2-react-grids';
import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext } from "react";
import  PinIcon  from "../assets/svg/PinIcon";

const EXT_CSS_GRID = `
    tr.selected {
        font-weight: 700;
        color: #fff
    }
    tr.selected td.e-rowcell {
        color: #fff!important
    }`;


const Gridbox = ({ data, handleSelectedTool, handleDockClick, isDocked}) => {

    let standorte = data;
    let gridInstance;

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;
    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;

    const pageSettings = { pageSize: 20 };
    const settings = { type: 'Multiple'};
    const sortSettings = { columns: [
        { field: 'checked', direction: 'Descending' }
    ] };
   
    const rowSelected = (args) => {
        handleSelectedTool(args.data.id, PrimaryColor);
    };

    const rowDataBound = (args) => {
        if (args.data) {
            if (args.data.checked === true) {
                args.row.classList.add('selected');
            }
        }
    };

    const buttonTemplate = () => {
        return (  <>  
            <PinIcon color={TextColor} pinned={isDocked}/>           
        </>)

    }

    const toolbarOptions = [
        { text: 'Search', align: 'Center' }, 
        { text: 'test', id: 'pinElement', tooltipText: 'Pin Grid', template : buttonTemplate, align: 'Right'   }
    ];

    const clickHandler = (args) => {
        if (args.item.id === 'pinElement') {
            handleDockClick();
        }
    };

    return (
        <div className='control-pane'>
            <style>
                    {EXT_CSS_GRID}
            </style>
            <GridComponent 
                dataSource={standorte}
                ref={g => gridInstance = g} 
                allowPaging={true}
                pageSettings={pageSettings}
                sortSettings={sortSettings} 
                allowSorting={true}
                selectionSettings={settings}
                rowSelected={rowSelected}
                rowDataBound={rowDataBound}
                toolbar={toolbarOptions} 
                toolbarClick={clickHandler} 
            >
                <ColumnsDirective>
                    <ColumnDirective field='checked' type= 'boolean' displayAsCheckBox= {true}/>
                    <ColumnDirective field='code' headerText='Code'/>
                    <ColumnDirective field='city' headerText='City'/>
                    <ColumnDirective field='country' headerText='Country' />
                    <ColumnDirective field='product' headerText='Product' />
                </ColumnsDirective>  
                <Inject services={[Page, Sort, Toolbar]}/>
            </GridComponent>
    </div>
    )

}

export default Gridbox;