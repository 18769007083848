import { world_map } from '../data/world_map.ts';
import { MapsComponent, 
    LayersDirective, 
    LayerDirective, 
    Inject, 
    MapsTooltip, 
    Marker, 
    MarkersDirective, 
    MarkerDirective, 
    Zoom  } from '@syncfusion/ej2-react-maps';
import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useEffect } from "react";


const GeneralMap = ({ data, handleSelectedTool, handleClickFromMap}) => {
    let mapInstance;

    const currentTheme = useContext(ThemeContext);
    let TextColor = currentTheme.theme.textColor;
    let primary = currentTheme.theme.primary;
    let widgetBorder = currentTheme.theme.widgetBorder;

    
    let backgroundColor = (currentTheme.dark == true ? currentTheme.theme.backgroundColor : '#fff');
    let fillColor = (currentTheme.dark == true ? '#efeeef' : widgetBorder);
  
    
    

    
    let standorte = data;

 
    const handleClick = (e) =>{
        handleClickFromMap();
        handleSelectedTool(e.data.id, primary);     
        
    }
    const handleMapClick = () => {
        handleClickFromMap();
    }
    

    useEffect(() => {
        mapInstance.refresh();
    },[standorte])
    
   
    return (
    <div className='control-pane'>
    
        <MapsComponent 
         ref={m => mapInstance = m}
       
        // load={load}
        height='100%'
        width='100%'
        id="maps" 
        zoomSettings={{
            enable: true, 
            shouldZoomInitially: true, 
            mouseWheelZoom : false
            
        }} 
        markerClick={(e) => handleClick(e) }
        click={(e) => handleMapClick(e) }
        background = {backgroundColor}
        mapsArea={{
            background: backgroundColor
        }}
    
        >
        <Inject services={[Marker, Zoom, MapsTooltip]}/>
            <LayersDirective>
                <LayerDirective background = {backgroundColor} shapeData={world_map} dataSource={standorte}
                 shapeSettings={{fill: fillColor } }>
                    <MarkersDirective>
                        <MarkerDirective 
                            visible={true}
                            shape= {'Circle'} 
                            colorValuePath= {'color'}
                            dataSource={standorte}
                            border={{
                                color: '#fff',
                                width: 1
                            }}
                        >
                        </MarkerDirective>
                        {/* {standorte.map((standort) => (

                        <MarkerDirective 
                            key={standort.id}
                            height={(standort.checked === true ? 16 : 8) }
                            width={(standort.checked === true ? 16 : 8) }
                     
                            fill={standort.color  }
                            border={{
                                color: '#fff',
                                width: 1
                            }}
                            shape='Circle' 
                            visible={true} 
                            dataSource={[
                                { latitude: standort.latitude, longitude: standort.longitude, id: standort.id, }
                            ]}
                            tooltipSettings={{
                                template: '<div id="markertooltiptemplate" style="width: 170px;opacity: 90%;background: rgba(53, 63, 76, 0.90);box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.40);padding:10px;border: 1px #abb9c6;border-radius: 4px;">' +
                                    '<div style="font-size:13px;color:#ffffff;font-weight: 500;"><center>${standort.name}</center></div>' +
                                    '<hr style="margin-top: 2px;margin-bottom:5px;border:0.5px solid #DDDDDD">' +
                                    '<div><span style="font-size:13px;color:#cccccc">Land : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${country}</span></div>' +
                                    '<div><span style="font-size:13px;color:#cccccc">Kontinent : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${continent}</span></div>' +
                                    '<div><span style="font-size:13px;color:#cccccc">Maschinen : </span><span style="font-size:13px;color:#ffffff;font-weight: 500;">${maschinen}</span></div></div>',
                                visible: true,
                                valuePath: ''
                            }}
                        >
                        </MarkerDirective>
                        ))} */}
                        
                    </MarkersDirective>             
                </LayerDirective>
            </LayersDirective>
        </MapsComponent>

    </div>);
}
export default GeneralMap;
