import { useRef, useState, useEffect, useContext } from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import useInput from '../hooks/useInput';
import useToggle from '../hooks/useToggle';
import axios from '../api/axios';
import jwt_decode from "jwt-decode";
import { Alert, Card, Container, Row, Col, Button } from 'react-bootstrap';
import { ThemeContext } from '../context/ThemeContext';

const Login = () => {
    const { setAuth } = useAuth();
    const { theme, toggle, dark } = useContext(ThemeContext);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const emailRef = useRef();
    const errRef = useRef();

    const [email, resetEmail, emailAttribs] = useInput('email', '')
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('/auth',
                JSON.stringify({ email, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            const userInfo = jwt_decode(accessToken).userInfo;
     
            setAuth({ accessToken, userInfo });
            resetEmail();
            setPwd('');
            navigate('/', from, { replace: true });


            if(userInfo.darkmode !== dark){
                toggle();
            }
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Email or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else if (err.response?.status === 403) {
                setErrMsg('Account locked for 5 minutes');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
            <Container className="h-100 login">
                <Row className="align-items-center h-100">
                    
                    <Col lg={{span: 4, offset:4}} sm={{span: 6 , offset : 3 }} xs={12}>
                        <div className="hack-ds-login-logo"></div>
                        <Card>

                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        type="text"
                                        id="email"
                                        ref={emailRef}
                                        autoComplete="off"
                                        {...emailAttribs}
                                        required
                                        className="form-control"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="password">Password:</label>
                                    <input
                                        type="password"
                                        id="password"
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                        required
                                        className="form-control"
                                    />
                                </div> 

                                <div className="mb-2">
                                    <div className="persistCheck ">
                                        <label className="cbox">
                                            Remember LogIn
                                            <input 
                                            id="persist"
                                            onChange={toggleCheck}
                                            // checked={(isMode(`${tool_key}${action}`) ? 'checked' : '')}
                                            checked={check}
                                            type="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                

                                <div className="my-3">
                                    <Button variant="primary" type="submit">
                                        Sign In
                                    </Button>
                                </div>
                            </form>
                            <Alert ref={errRef} variant={errMsg ? "danger" : "hide"}>{errMsg}</Alert>
                            
                        </Card>
                    </Col>
                </Row>
        </Container>
    )
}

export default Login
