import { Outlet } from "react-router-dom"
import Header from "../components/Header";
import { Card , Container, Col, Row } from 'react-bootstrap';
import SettingsNavigation from '../components/SettingsNavigation';


const SettingsTemplate = () => {
 
   
    
    return (
        <>       
            <Header/>
                <Container className="pt-5 mt-5 settings">
                    <Row>
                        <Col sm={3}>
                            <Card>
                                <Card.Title>Settings </Card.Title> 
                                <SettingsNavigation />
                            </Card>
                        </Col>
                        <Col sm={9}>    
                            <Card>
                                <Outlet />    
                            </Card>                
                        </Col>
                    </Row>     
            </Container>           
        </>
    )
}

export default SettingsTemplate;
