import { ThemeContext, themes } from '../context/ThemeContext';
import { useContext, useState, useEffect, useRef } from "react";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, DateTime, DataLabel, ScatterSeries, Zoom  } from '@syncfusion/ej2-react-charts';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import TimelapsePast from '../assets/svg/TimelapsePast';
import TimelapseFuture from '../assets/svg/TimelapseFuture';
import SettingsIcon from '../assets/svg/SettingsIcon';
import HelpIcon from '../assets/svg/HelpIcon';
import { Col, Row } from 'react-bootstrap';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';

// Datepicker fomrat:
// https://www.syncfusion.com/forums/143984/change-date-picker-view-format-to-week-or-month

   
const NoiseIDN = ({ selectedTool }) => {


    createSpinner({
        target: document.getElementById('panel_11_content'),
    });

    const [message, setMessage] = useState();
    const date = new Date();
    const [statedNoiseData, setStatedNoiseData] = useState([]);
    
    const [dateRange, setDaterange] = useState(7);
   

    let firstDayofCurrentWeek = date.getDate() - date.getDay() + 1;
    firstDayofCurrentWeek =  new Date(date.setDate(firstDayofCurrentWeek)).toUTCString();
    
    let LSStart = localStorage.getItem('session_scatter_start');
    let LSEnd = localStorage.getItem('session_scatter_end');

    let lastDayofCurrentWeek = new Date(date.setDate(date.getDate()+6)).toUTCString();
    

    const [scatterDateRange, setScatterDateRange] = useState([new Date(LSStart ? LSStart : firstDayofCurrentWeek), new Date(LSEnd ? LSEnd : lastDayofCurrentWeek)]);// first and last day of current week

    let start = new Date(scatterDateRange[0]);
    let end = new Date(scatterDateRange[1]);

    const TZoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
   
    useEffect(() => {

        showSpinner(document.getElementById('panel_11_content'));
        let unMounted = false;
        const getNoiseData = async () => {
            if(!selectedTool == 0  ){ // selected = mold from map/grid

        
                let start = new Date (scatterDateRange[0] - TZoffset);
                start = start.toISOString().split('T', 1)[0];

                let ende = new Date (scatterDateRange[1] - TZoffset);
                ende = ende.toISOString().split('T', 1)[0];
                
                
                const response = await axiosPrivate.get('/molddata/noise/1/mold_id/'+ selectedTool +'/start/' + start + ' 00:00/end/' + ende + ' 23:00', {

            }).catch(function(error){
                if (error.response) {
                    console.error(error.response.data.message);
                }
            });
                !unMounted &&
                setStatedNoiseData([...response.data]);            
                if(response.data.length === 0){
                    setMessage('Change daterange to view Noise IDN');
                }
            }
            else{
                setMessage('Select mold from map and/or change daterange to view Noise IDN');   
            }
            hideSpinner(document.getElementById('panel_11_content'))   
           
        }

        getNoiseData();

        return () => {
            unMounted = true;
        }
    }, [selectedTool, scatterDateRange]);  

  
    const handleScatterDateChange = (start, ende) => { 
       let temparray = [start, ende] 
       setScatterDateRange(temparray);
       localStorage.setItem('session_scatter_start', start);
       localStorage.setItem('session_scatter_end', ende);
    };

    




    

    let chart = useRef(null); 
    const axiosPrivate = useAxiosPrivate();   
    // let startDate = new Date(scatterDateRange[0]);
    // let endDate = new Date(scatterDateRange[1]);

    // var diffDays = endDate.getTime() - startDate.getTime(); 
    // diffDays = diffDays / (1000 * 3600 * 24);

    // const [dateRange, setDaterange] = useState(diffDays);

    const currentTheme = useContext(ThemeContext);
    let BackgroundColor = currentTheme.theme.widgetBg;
    let TextColor = currentTheme.theme.textColor;

    let PrimaryColor = currentTheme.theme.primary;
    let Yellow = currentTheme.theme.yellowMid;
    let Red = currentTheme.theme.red;
    let Blue = currentTheme.theme.blue;
    let helpDialogInstance;


    const marker = { visible: false, width: 2, height: 2, shape: 'Circle'};

    const [status, setStatus] = useState(false);

    const settingsButtonClick = () => {
        setStatus(!status);
    };
    const dialogClose = () => {
        setStatus(false);
  
    };
    const dialogOpen = () => {
        setStatus(true);
     
    };

    let buttons;
    const dlgButtonClick = () => {
        dialogClose();
    };
    buttons = [
        {
            click: dlgButtonClick,
            buttonModel: {
                content: 'save',
                isPrimary: true,
            }
        }
    ];


    const onChange = (e) => {
        let start = e.startDate;
        let end = e.endDate;
    //    let text = e.text; dd/mm/yyyy - dd/mm/yyyy
        setDaterange(e.daySpan);
        handleScatterDateChange(start, end);
      }; 
      const toggleDatespan = (daterange, jumpto = 'future') => {

        daterange = (jumpto == "future" ? daterange : -daterange);
        start = new Date(start);
        end = new Date(end);

        let newstart =  new Date(start.setDate(start.getDate() + daterange));
        let newend = new Date(end.setDate(end.getDate() + daterange));  

        handleScatterDateChange(newstart, newend);
    };

   
    const data = statedNoiseData;

    const primaryxAxis = { 
        valueType: 'DateTime',  
        // title: 'Time', 
        labelFormat: 'dd.MM - h:mm',
        //  interval: 1, 
        labelRotation:  -45 , 
        labelStyle: {color: TextColor}, 
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        enableAutoIntervalOnZooming: true,
    };
    const primaryyAxis = {
        // title: 'Value',
        majorTickLines: { width: 0 },
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        lineStyle: {width: 0  },
        labelFormat: 'n1' ,
        labelStyle: {color: TextColor}, 
        // interval: 10,
    };
    const refreshChart = () => { 
        chart.current.refresh();
  
    }
    const margin = { left: 80, right: 80, top: 60, bottom: 30 };
    const helpButtonClick = () => {
        helpDialogInstance.show();
    }

    const loaded = () => {
        hideSpinner(document.getElementById('panel_11_content'));
    }
return (
<>   
    <div className="widgetheader">
        <div className="float-end" onClick={settingsButtonClick} >
            <SettingsIcon color="#fff" />
        </div>
        <div className="float-end"  onClick={helpButtonClick}>
            <HelpIcon color="#fff" />
        </div>
        <DialogComponent 
            id="helper" 
            header='Noise IDN' 
            showCloseIcon={true} 
            visible={false}
            width='250px' height='150px' 
            ref={dialog => helpDialogInstance = dialog} 
            closeOnEscape={false} 
            target='#panel_11_content'
            >  
            Display of the Noise IDN points of up to 4 sensors over time - Standard period 24h - One value per cycle  
        </DialogComponent>

        <DialogComponent 
            id="defaultdialogScatter" 
            showCloseIcon={true} 
            zIndex = {9999}
            width="500px" 
            header="Settings" 
            visible={status}
            target="#panel_11_content"
            buttons={buttons}
            open={dialogOpen}
            close={dialogClose}
        >
            <Row>                
                <Col>
                    No settings available
                </Col>
            </Row>
        </DialogComponent>
        <div className="datecontainer">
            <DateRangePickerComponent 
                id="daterangepicker" 
                startDate={start} 
                endDate={end} 
                placeholder="Select a range" 
                change={onChange}
                format='dd.MM.yyyy'
                firstDayOfWeek = {1}
            />
        </div>
    </div>
    <div className="timelapse past" onClick={() => toggleDatespan(dateRange, "past")}> <TimelapsePast color={TextColor} val={" - " + dateRange + "d"}  /> </div>
    <div className="timelapse future" onClick={() => toggleDatespan(dateRange )}> <TimelapseFuture color={TextColor} val={" + " + dateRange + "d"}  /> </div>


{(data.length == 0 ? <h2 className='text-center pt-5'> {message} </h2> : 
     
    <ChartComponent 
        style={{ backgroundColor : BackgroundColor, color: TextColor}}
        background={BackgroundColor}
        primaryXAxis={primaryxAxis} 
        primaryYAxis={primaryyAxis} 
        margin={margin}         
        enableCanvas={true}
        enablePersistence={true}
        loaded = {loaded}

        zoomSettings={{
            enableSelectionZooming: true,
            enableScrollbar: true,
            toolbarItems: ['Reset'],
            mode: "X, Y",
        }}
        title='Noise IDN'
        titleStyle={{color: TextColor, fontWeight: '400', position: 'top'}}
        chartArea={{ border: { width: 0 } }} 

        >
        <Inject services={[ScatterSeries, Legend, DataLabel, Category, Zoom, DateTime]}/>
        <SeriesCollectionDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_0' name="Sensor 1" type='Scatter' fill={PrimaryColor} opacity='0.7' marker={marker}></SeriesDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_1' name="Sensor 2" type='Scatter' fill={Red} opacity='0.7' marker={marker}></SeriesDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_2' name="Sensor 3" type='Scatter' fill={Blue} opacity='0.7' marker={marker}></SeriesDirective>
            <SeriesDirective dataSource={data} xName='time' yName='noise_idn_3' name="Sensor 4" type='Scatter' fill={Yellow} opacity='0.7' marker={marker}></SeriesDirective>
     
        </SeriesCollectionDirective>
       
    </ChartComponent>
    
)}
</>
)

}
;
export default NoiseIDN;
