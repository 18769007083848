import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from '../hooks/useAuth';
import { Card, Container, Col, Row, Alert } from 'react-bootstrap';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import RequestEmail from '../emails/Request';
import { render } from "@react-email/render";

export const ArrangeCallback = () => {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const [callbackNumber, setCallbackNumber] = useState(auth.userInfo.email);
    const [name, setName] = useState(auth.userInfo.name);
    const [notice, setNotice] = useState()
    const [noticeType, setNoticeType] = useState();


    const sendMail = async e => {
        try {
            const data = { 
                users_id: auth.userInfo.id,
                to: '', 
                subject: 'Callback request - ' + name, 
                html: render(<RequestEmail type="Callback request" name={name} contact={callbackNumber}/>)
            };

            await axiosPrivate.post(`/email`, {
                data
            }).catch(function(error){
                if (error.response) {
                    console.log(error.response.data.message)  
                    throw "Error submitting";
                }
            }); 

            setNotice('Request submitted');
            setNoticeType('success'); 
        } catch (error) {
            setNotice(error);
            setNoticeType('danger'); 
        }
    }

return(
    <>
        <Row>     
            
            <Col xs={12}>
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    required
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </Col>
            <Col xs={12} className="mb-4">
                <label htmlFor="email">Callback phone number</label>
                <input
                    type="text"
                    id="phone"
                    autoComplete="off"
                    required
                    value={callbackNumber}
                    onChange={e => setCallbackNumber(e.target.value)}
                />
            </Col>  
            <Col xs={12}>
                <p className="mb-3">We will call you back immediately during our business hours.</p>
                {(notice ? <Alert className="my-2" show={true} variant={ noticeType }>{ notice }</Alert> : '')}
            </Col>

            <Col sm={12} className="my-3">         
                <ButtonComponent className="primary float-end" onClick={e => sendMail(e)}>
                    Request Callback
                </ButtonComponent>
            </Col> 
        </Row>

   
    </>
)
}

export default ArrangeCallback ;