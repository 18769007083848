import { NavLink } from 'react-router-dom';
import useAuth from "../hooks/useAuth";

const SettingsNavigation = () => {
    const { auth } = useAuth();

    return (
        <>        
            <ul className="settingsnavigation">
            <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')}to="/settings/overviewsettings">
                        <span>Overview Settings</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')}to="/settings/alerts">
                        <span>Alerts</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/downloads">
                        <span>Downloads</span>
                    </NavLink>
                </li>

            {auth.userInfo.key_user ? 
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/roles">
                        <span>Roles</span>
                    </NavLink>
                </li> :
                ''
            }

            {auth.userInfo.modes.includes(21) ? 
                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/users">
                        <span>Users</span>
                    </NavLink>
                </li> :
                ''
            }
                

                <li>
                    <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/settings/molds">
                        <span>Mold Connections</span>
                    </NavLink>
                </li>
            </ul> 
        </>
    )
}

export default SettingsNavigation
