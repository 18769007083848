import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"

const Missing = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <section>
            <Container>
                <Row>
                    <Col sm={3}></Col>
                    <Col sm={6}>
                        <Card className="mt-5">                      
                            <div className="m-4">
                                <h1>Page not found</h1>
                                <Button variant="primary" onClick={goBack}>
                                    Go back
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Missing
